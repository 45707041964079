<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            loader(v-if="loaderSurvey")
            template(v-else-if="currentSurvey.uuid != undefined")
                header-component
                    v-btn.no-margin(absolute dark fab top right color='primary' @click.stop="drawerRight = !drawerRight")
                        v-icon search
                v-navigation-drawer.filters.grey.darken-3(fixed v-model="drawerRight" right app width="246")
                    v-form
                        v-toolbar.grey.darken-1(flat)
                            v-text-field(v-model="filter.query" :placeholder="$t('Buscar...')" dark)
                            v-icon(dark) search
                        .filters-content
                            .white--text.mb-2 {{$t('Filtrar por')}}:
                            v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                                v-text-field(slot='activator' :label="$t('Desde')" v-model='filter.from' readonly dark color="white")
                                v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                    v-spacer
                                    v-btn(flat color='primary' @click.stop='modalDateStart = false') {{ $t('Cancelar') }}
                                    v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{ $t('OK') }}
                            v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                                v-text-field(slot='activator' :label="$t('Hasta')" v-model='filter.to' readonly dark color="white")
                                v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                    v-spacer
                                    v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{ $t('Cancelar') }}
                                    v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{ $t('OK') }}
                            .btns.btns-right
                                v-btn(@click="cleanFilter" color="primary") {{ $t('Limpiar') }}
                v-content
                    v-container.grid-list-xl(fluid)
                        h1 {{$t('Resultados de la encuesta')}}
                        template(v-if="preload")
                            h3 {{$t('Cargando')}}...
                        template(v-else)
                            v-data-table(
                                :headers='headers'
                                :items='surveys'
                                :pagination.sync='pagination'
                                :rows-per-page-items="$root.rowsPageItems"
                                :rows-per-page-text="$root.rowsPageText"
                                :total-items="total"
                                item-key='uuid'
                                :loading="loading")
                                template(slot="no-results")
                                    | {{$t('No se han encontrado resultados')}}
                                template(slot="no-data")
                                    | {{$t('No se han encontrado resultados')}}
                                template(slot='headers' slot-scope='props')
                                    tr
                                        th(v-for='(header, i) in props.headers' :key='i' :align="header.align")
                                            span.subheading {{ $t(header.text) }}
                                template(slot='items' slot-scope='props')
                                    tr
                                        td {{ props.item.c_names || $t('No registra') }}
                                        td {{ props.item.c_email || $t('No registra') }}
                                        td {{ props.item.c_phone || $t('No registra') }}
                                        td {{ props.item.c_whatsapp || $t('No registra') }}
                                        td {{ props.item.c_country || $t('No registra') }}
                                        td {{ props.item.c_state || $t('No registra') }}
                                        td {{ props.item.c_city || $t('No registra') }}
                                        td {{ props.item.c_gender || $t('No registra') }}
                                        td {{ props.item.c_age || $t('No registra') }}
                                        td {{ props.item.c_document || $t('No registra') }}
                                        td {{ props.item.names || $t('No registra') }}
                                        td {{ props.item.email || $t('No registra') }}
                                        td {{ props.item.phone || $t('No registra') }}
                                        td(v-if="props.item.phone")
                                            a(:href="props.item.whatsapp" target="_blank") {{ props.item.whatsapp }}
                                        td(v-else)
                                            | {{ $t('No registra') }}
                                        td {{ props.item.datecreated }}
                                        td {{ props.item.refer }}
                                        td {{ props.item.refer_anom }}
                                        td {{ props.item.ip }}
                                        td {{ props.item.ip_country }}
                                        td {{ props.item.ip_state }}
                                        td {{ props.item.ip_city }}
                                        td(v-if="isAvailable('countryid')") {{ props.item.country || $t('No registra') }}
                                        td(v-if="isAvailable('stateid')") {{ props.item.state || $t('No registra') }}
                                        td(v-if="isAvailable('cityid')") {{ props.item.city || $t('No registra') }}
                                        td(v-if="isAvailable('zones')" style="min-width:220px")
                                            template(v-if="props.item.zones.length")
                                                template(v-for="zone in props.item.zones")
                                                    small {{zone.division}}: {{zone.name}}
                                                    br
                                            template(v-else)
                                                | {{ $t('No registra') }}
                                        td(v-if="isAvailable('gender')") {{ props.item.gender || $t('No registra') }}
                                        td(v-if="isAvailable('birthdate')") {{ props.item.age || $t('No registra') }}
                                        td(v-if="isAvailable('document')") {{ props.item.document || $t('No registra') }}
                                        template(v-if="Object.keys(props.item.custom_form).length")
                                            td(v-for="c in props.item.custom_form")
                                                | {{ c }}
                                        template(v-if="Object.keys(props.item.custom_survey).length")
                                            td(v-for="c in props.item.custom_survey")
                                                | {{ c }}
                                template(slot='pageText' slot-scope='props')
                                    | {{$t('Filas')}} {{ props.pageStart }} - {{ props.pageStop }} {{$t('de')}} {{ props.itemsLength }}
                        v-btn(v-if="surveys.length" :loading="loadingBtn" @click="download" fixed dark fab bottom left color='primary')
                            v-icon file_download
            template(v-else)
                v-content
                    v-container.grid-list-xl.text-xs-center(fluid)
                        h2 {{ $t('No se han encontrado resultados') }}
                        v-btn(color="primary" @click="$router.push({path:'/survey'})") {{ $t('Ir a encuestas') }}
</template>

<script>

    import auth from 'mixins/auth'
    import config from 'src/config'
    import _ from 'lodash/debounce'
    import fn from 'mixins/fn'
    
    export default {
        mixins: [auth, fn],
        metaInfo() {
            return {
                title: this.$t('Resultados de la encuesta')
            }
        },
        data() {
            return {
                preload: false,
                total: 0,
                loading: false,
                pagination: {
                    page: 1,
                    rowsPerPage: 20
                },
                drawerRight: false,
                modalDateStart: false,
                modalDateEnd: false,
                filter: {},
                filterSchema: {
                    query : '',
                    from  : null,
                    to    : null
                },
                surveys : [],
                headers: [
                    {
                        text: 'Nombres (Importado)',
                        align: 'left',
                        value: 'cc_names',
                        sortable: false
                    },
                    {
                        text: 'Correo electrónico (Importado)',
                        align: 'left',
                        value: 'cc_email',
                        sortable: false
                    },
                    {
                        text: 'Teléfono (Importado)',
                        align: 'left',
                        value: 'cc_phone',
                        sortable: false
                    },
                    {
                        text: 'Whatsapp (Importado)',
                        align: 'left',
                        value: 'cc_whatsapp',
                        sortable: false
                    },
                    {
                        text: 'País (Importado)',
                        align: 'left',
                        value: 'cc_country',
                        sortable: false
                    },
                    {
                        text: 'Estado/Provincia (Importado)',
                        align: 'left',
                        value: 'cc_state',
                        sortable: false
                    },
                    {
                        text: 'Ciudad (Importado)',
                        align: 'left',
                        value: 'cc_city',
                        sortable: false
                    },
                    {
                        text: 'Género (Importado)',
                        align: 'left',
                        value: 'cc_gender',
                        sortable: false
                    },
                    {
                        text: 'Edad (Importado)',
                        align: 'left',
                        value: 'cc_age',
                        sortable: false
                    },
                    {
                        text: 'Documento (Importado)',
                        align: 'left',
                        value: 'cc_document',
                        sortable: false
                    },
                    {
                        text: 'Nombres',
                        align: 'left',
                        value: 'names',
                        sortable: false
                    },
                    {
                        text: 'Correo electrónico',
                        align: 'left',
                        value: 'email',
                        sortable: false
                    },
                    {
                        text: 'Teléfono',
                        align: 'left',
                        value: 'phone',
                        sortable: false
                    },
                    {
                        text: 'Whatsapp',
                        align: 'left',
                        value: 'whatsapp',
                        sortable: false
                    },
                    {
                        text: 'Fecha de encuesta',
                        align: 'left',
                        value: 'datecreated',
                        sortable: false
                    },
                    {
                        text: 'Referidor',
                        align: 'left',
                        value: 'refer',
                        sortable: false
                    },
                    {
                        text: 'Referidor (Importado)',
                        align: 'left',
                        value: 'refer_anom',
                        sortable: false
                    },
                    {
                        text: 'IP',
                        align: 'left',
                        value: 'ip',
                        sortable: false
                    },
                    {
                        text: 'País (Localización)',
                        align: 'left',
                        value: 'ip_country',
                        sortable: false
                    },
                    {
                        text: 'Estado/provincia (Localización)',
                        align: 'left',
                        value: 'ip_state',
                        sortable: false
                    },
                    {
                        text: 'Ciudad (Localización)',
                        align: 'left',
                        value: 'ip_city',
                        sortable: false
                    },
                    {
                        text: 'País',
                        align: 'left',
                        value: 'country',
                        sortable: false
                    },
                    {
                        text: 'Estado/Provincia',
                        align: 'left',
                        value: 'state',
                        sortable: false
                    },
                    {
                        text: 'Ciudad',
                        align: 'left',
                        value: 'city',
                        sortable: false
                    },
                    {
                        text: 'Zonas',
                        align: 'left',
                        value: 'zone',
                        sortable: false
                    },
                    {
                        text: 'Genéro',
                        align: 'left',
                        value: 'gender',
                        sortable: false
                    },
                    {
                        text: 'Edad',
                        align: 'left',
                        value: 'age',
                        sortable: false
                    },
                    {
                        text: 'Documento de identificación',
                        align: 'left',
                        value: 'document',
                        sortable: false
                    }
                ],
                loadingBtn: false,
                currentSurvey: {},
                loaderSurvey: true
            }
        },
        computed: {
            surveyid(){
                return this.$route.params.survey
            }
        },
        watch: {
            filter: {
                handler(){

                    this.preload = true
                    this.total = 0
                    this.fetchResult()
                },
                deep: true
            },
            pagination: {
                handler () {
                    this.fetchResult()
                },
                deep: true
            }
        },
        async created(){

            this.filter = Object.assign({}, this.filterSchema)
            
            this.loaderSurvey = true
            await this.fetchMeta()
            await this.fetchCustomForm()
            this.loaderSurvey = false

            await this.fetchForm()
        },
        methods: {

            fetchMeta() {
                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/survey/meta-id', {
                            params: {
                                surveyid : this.surveyid
                            }
                        }).then((r) => {

                            let data = r.data
                            if (data.response) {
                                this.currentSurvey = data.data
                            }
                            resolve()
                        }).catch(() => {})
                    })
                })
            },

            fetchCustomForm() {
                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/network/form', {
                            params: {
                                networkid : this.currentSurvey.networkid,
                            }
                        }).then((r) => {

                            let data = r.data
                            if (data.response) {
                                
                                let customForm = data.data.custom
                                this.availableRow = data.data.available || []

                                if(!this.isAvailable('cityid')){

                                    let idx = this.headers.findIndex(h => h.value == 'city')
                                    this.headers.splice(idx, 1)
                                }
                                if(!this.isAvailable('zones')){
                                    
                                    let idx = this.headers.findIndex(h => h.value == 'zone')
                                    this.headers.splice(idx, 1)
                                }
                                if(!this.isAvailable('birthdate')){
                                    
                                    let idx = this.headers.findIndex(h => h.value == 'age')
                                    this.headers.splice(idx, 1)
                                }
                                if(!this.isAvailable('document')){
                                    
                                    let idx = this.headers.findIndex(h => h.value == 'document')
                                    this.headers.splice(idx, 1)
                                }
                                if(!this.isAvailable('gender')){
                                    
                                    let idx = this.headers.findIndex(h => h.value == 'gender')
                                    this.headers.splice(idx, 1)
                                }

                                for(let c of customForm){

                                    this.headers.push({
                                        text  : c.placeholder,
                                        align : 'left',
                                        value : c.uuid,
                                        sortable: false
                                    })
                                }
                            }
                            resolve()
                        }).catch(() => {})
                    })
                })
            },

            fetchForm(){
                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/survey/form', {
                            params: {
                                survey: this.surveyid || ''
                            }
                        }).then((r) => {

                            let customForm = r.data.data.custom || []

                            for(let c of customForm){

                                if(this.headers.some((h) => h.value == c.uuid)){
                                    continue
                                }

                                this.headers.push({
                                    text  : c.placeholder,
                                    align : 'left',
                                    value : c.uuid,
                                    sortable: false
                                })
                            }
                            
                            resolve()
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },

            download(){

                this.loadingBtn =  true
                let userid = this.$root.profile.uuid

                let filter = this.$qs.stringify({
                    filter   : JSON.stringify(this.filter),
                    surveyid : this.surveyid 
                })

                this.$api(this, (xhr) => {
                    xhr.post('/survey/result', filter).then((r) => {

                        let data = r.data
                        this.loadingBtn = false

                        if(data.response){
                            let url = config.apiurl + 'download?id=' + userid + '&file=' + data.data
                            window.location.href = url
                        }
                    }).catch(() => {})
                })
            },

            fetchResult: _(function(){

                this.loading = true
                let filter = this.filter
                let params = {
                    filter   : JSON.stringify(filter),
                    surveyid : this.surveyid,
                    limit    : this.pagination.rowsPerPage,
                    offset   : (this.pagination.page - 1) * this.pagination.rowsPerPage,
                }

                this.$api(this, (xhr) => {
                    xhr.get('/survey/result', { params: params }).then((r) => {

                        let data = r.data
                        this.preload = false
                        this.loading = false

                        if(data.response){
                            this.surveys = data.data
                            this.total   = data.total 
                        }

                    }).catch(() => {
                        this.preload = false
                        this.loading = false
                    })
                })

            }, 500),

            cleanFilter(){
                this.filter = Object.assign({}, this.filterSchema)
            }
        }
    }
    
</script>